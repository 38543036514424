import React from 'react'
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductsMenu from '../components/products-menu'

const ProductsPage = ({ data, location }) => {

    const itemList = []

    const products = data.allShopifyProduct.edges

    for(let i=0; i<products.length; i++) {
        itemList.push({
            "@type": "ListItem",
            "position": i,
            "url": `https://shop.rarechicago.com/product/${products[i].node.handle}/`
        })
    }

    const schema = {
        "@context": "http://schema.org",
        "@type": "ItemList",
        "ItemListElement": itemList
    }

    const socialCardImage = data.allFile.edges[0].node.childImageSharp.resize

    return(
        <Layout>
            <SEO
                title="Products"
                description="Rare Threads. Shop our selection of hats, shirts, pants, hoodies, sweatshirts, accessories, gifts, and more. Brought to you by Rare Chicago, a digital marketing and web development company."
                pathname={location.pathname}
                image={socialCardImage}
                schema={schema}
            />
            <ProductsMenu />
        </Layout>
    )
}

export default ProductsPage;

export const pageQuery = graphql`
    query {
        allFile(filter: {name: {eq: "rarethreads-socialcard-home"}}) {
            edges {
            node {
                childImageSharp {
                resize(width: 1200) {
                    src
                    height
                    width
                }
                }
            }
            }
        }
        allShopifyProduct {
            edges {
            node {
                handle
            }
            }
        }
    }
`