import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const AccoutrementsGrid = () => {
    const data = useStaticQuery(graphql`
    query listAccoutrements {
        shopifyCollection(title: {eq: "Accoutrements"}) {
          products {
            title
            handle
            shopifyId
            images {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 600) 
                }
              }
            }
            variants {
              price
            }
          }
        }
      }
    `)

    return(
        <div className="grid grid-cols-1 gap-y-6  tablet:grid-cols-2 tablet:gap-x-6 laptop:grid-cols-3">
            {data.shopifyCollection.products.map(product => (
                <Link
                    to={`/product/${product.handle}/`}
                    key={product.shopifyId}
                    className="product-card bg-white text-dkblue p-2 flex flex-wrap"
                >
                    <div className="w-full">
                        <h3>{product.title}</h3>
                    </div>
                    <div className="w-full">
                        <GatsbyImage
                            alt={product.title}
                            image={getImage(product.images[0].localFile)}
                            className="mb-6"
                        />
                    </div>
                    <div className="w-full self-end">
                        <div className="flex justify-between bg-gray-200 p-2">
                            <span className="font-bold">${product.variants[0].price}</span>
                            <span>View details &gt;</span>
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    )
}

export default AccoutrementsGrid;