import React, { useState, useRef, useContext, useCallback, useEffect } from 'react'
import StoreContext from '../context/StoreContext'

import AccoutrementsGrid from './accoutrements-grid'
import ShirtsGrid from './shirts-grid'
import PantaloonsGrid from './pantaloons-grid'
import HoodiesAndHoodlessGrid from './hoodies-and-hoodless-grid'
import KnickKnacksGrid from './knick-knacks-grid'

import styles from '../css/products-menu.module.css'

import ChevronsRightSVG from '../icons/chevrons-right.svg'

const ProductsMenu = () => {

    const { mobileProductsMenu, setMobileProductsMenu } = useContext(StoreContext)

    const [currentSection, setCurrentSection] = useState('')

    const AccoutrementsRef = useRef();
    const ShirtsRef = useRef();
    const PantaloonsRef = useRef();
    const HoodiesAndHoodlessRef = useRef();
    const KnickKnacksRef = useRef();

    const initializeState = useCallback(() => {
        let screenSize = window.innerWidth;
        let headerOffset = screenSize >= 992 ? 150 : 130;
        let shirtsSection = ShirtsRef.current.offsetTop;
        let pantaloonsSection = PantaloonsRef.current.offsetTop;
        let hoodiesAndHoodlessSection = HoodiesAndHoodlessRef.current.offsetTop;
        let knickKnacksSection = KnickKnacksRef.current.offsetTop;
        let windowPosition = window.scrollY;
        if (windowPosition < shirtsSection - headerOffset ) {
            setCurrentSection('accoutrements')
        }
        if (windowPosition < pantaloonsSection - headerOffset && windowPosition >= shirtsSection - headerOffset) {
            setCurrentSection('shirts')
        }
        if (windowPosition < hoodiesAndHoodlessSection - headerOffset && windowPosition >= pantaloonsSection - headerOffset) {
            setCurrentSection('pantaloons')
        }
        if (windowPosition < knickKnacksSection - headerOffset && windowPosition >= hoodiesAndHoodlessSection - headerOffset) {
            setCurrentSection('hoodiesAndHoodless')
        }
        if (windowPosition >= knickKnacksSection - headerOffset) {
            setCurrentSection('knickKnacks')
        }
    }, [ShirtsRef, PantaloonsRef, HoodiesAndHoodlessRef, KnickKnacksRef]);

    const handleScroll = useCallback(() => {
        let screenSize = window.innerWidth;
        let headerOffset = screenSize >= 992 ? 150 : 130;
        let shirtsSection = ShirtsRef.current.offsetTop;
        let pantaloonsSection = PantaloonsRef.current.offsetTop;
        let hoodiesAndHoodlessSection = HoodiesAndHoodlessRef.current.offsetTop;
        let knickKnacksSection = KnickKnacksRef.current.offsetTop;
        let windowPosition = window.scrollY;
        if (windowPosition < shirtsSection - headerOffset ) {
            setCurrentSection('accoutrements')
        }
        if (windowPosition < pantaloonsSection - headerOffset && windowPosition >= shirtsSection - headerOffset) {
            setCurrentSection('shirts')
        }
        if (windowPosition < hoodiesAndHoodlessSection - headerOffset && windowPosition >= pantaloonsSection - headerOffset) {
            setCurrentSection('pantaloons')
        }
        if (windowPosition < knickKnacksSection - headerOffset && windowPosition >= hoodiesAndHoodlessSection - headerOffset) {
            setCurrentSection('hoodiesAndHoodless')
        }
        if (windowPosition >= knickKnacksSection - headerOffset) {
            setCurrentSection('knickKnacks')
        }
    }, [ShirtsRef, PantaloonsRef, HoodiesAndHoodlessRef, KnickKnacksRef]);

    useEffect(() => {
        initializeState();

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }

    },[handleScroll, initializeState])

    const mobileScrollTo = (ref) => {
        let headerOffset = 120;
        let elementPosition = ref.current.offsetTop;
        let offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    const desktopScrollTo = (ref) => {
        let headerOffset = 140;
        let elementPosition = ref.current.offsetTop;
        let offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });

   }

    const handleMobileNavigation = (ref) => {
        mobileScrollTo(ref);
        setMobileProductsMenu(false);
    }

    const handleMobileProductsMenu = () => {
        if(mobileProductsMenu === true) {
            setMobileProductsMenu(false);
        } else {
            setMobileProductsMenu(true);
        }
    }

    return(
        <>
            {/* Products Navbar */}
            <div className={`${styles.productsNavbar} bg-seagreen text-black flex justify-around items-center laptop:bg-dkblue laptop:bg-opacity-90`}>

                {/* Mobile Only Product Menu Button */}
                <button
                    className="font-bold m-0 flex items-center laptop:hidden"
                    onClick={() => handleMobileProductsMenu()}
                >
                    <span>Browse by category</span>
                    <img
                        alt="Chevrons right icon"
                        className={`${styles.chevrons} ${mobileProductsMenu === true && styles.rotateChevrons}`}
                        src={ChevronsRightSVG}
                    />
                </button>

                {/* Desktop Product Navigation */}
                <div className="hidden laptop:flex w-full h-full justify-center">
                    <button 
                        className={`${styles.desktopProductNavButton} ${currentSection === 'accoutrements' && styles.desktopProductNavButtonActive}`}
                        onClick={() => desktopScrollTo(AccoutrementsRef)}
                    >
                        Accoutrements
                    </button>
                    <button 
                        className={`${styles.desktopProductNavButton} ${currentSection === 'shirts' && styles.desktopProductNavButtonActive}`}
                        onClick={() => desktopScrollTo(ShirtsRef)}
                    >
                        Shirts
                    </button>
                    <button 
                        className={`${styles.desktopProductNavButton} ${currentSection === 'pantaloons' && styles.desktopProductNavButtonActive}`}
                        onClick={() => desktopScrollTo(PantaloonsRef)}
                    >
                        Pantaloons
                    </button>
                    <button 
                        className={`${styles.desktopProductNavButton} ${currentSection === 'hoodiesAndHoodless' && styles.desktopProductNavButtonActive}`}
                        onClick={() => desktopScrollTo(HoodiesAndHoodlessRef)}
                    >
                        Hoodies & Hoodless
                    </button>
                    <button 
                        className={`${styles.desktopProductNavButton} ${currentSection === 'knickKnacks' && styles.desktopProductNavButtonActive}`}
                        onClick={() => desktopScrollTo(KnickKnacksRef)}
                    >
                        Knick Knacks
                    </button>
                </div>

            </div>

            {/* Mobile Products Menu */}
            <div className={`${styles.mobileProductsMenu} ${mobileProductsMenu === true && styles.mobileProductsMenuActive} bg-white text-black pt-6 page-padding`}>
                <button
                    className="block mb-6"
                    onClick={() => handleMobileNavigation(AccoutrementsRef)}
                >
                    <p className="font-bold m-0">Accoutrements</p>
                </button>
                <button
                    className="block mb-6"
                    onClick={() => handleMobileNavigation(ShirtsRef)}
                >
                    <p className="font-bold m-0">Shirts</p>
                </button>
                <button
                    className="block mb-6"
                    onClick={() => handleMobileNavigation(PantaloonsRef)}
                >
                    <p className="font-bold m-0">Pantaloons</p>
                </button>
                <button
                    className="block mb-6"
                    onClick={() => handleMobileNavigation(HoodiesAndHoodlessRef)}
                >
                    <p className="font-bold m-0">Hoodies & Hoodless</p>
                </button>
                <button
                    className="block mb-6"
                    onClick={() => handleMobileNavigation(KnickKnacksRef)}
                >
                    <p className="font-bold m-0">Knick Knacks</p>
                </button>
            </div>

            <div className={`${styles.mainContent} w-full`}>
                <div className="max-viewport mx-auto">
                    <div ref={AccoutrementsRef} className={`${styles.sectionTitleContainer} flex bg-white mb-6 pt-6 justify-around laptop:bg-dkblue laptop:justify-start`}>
                        <h2 className="text-dkblue laptop:text-white">Accoutrements</h2>
                    </div>
                    <div className="page-padding mb-14">
                        <AccoutrementsGrid />
                    </div>
                    <div ref={ShirtsRef} className={`${styles.sectionTitleContainer} flex bg-white mb-6 pt-6 justify-around laptop:bg-dkblue laptop:justify-start`}>
                        <h2 className="text-dkblue laptop:text-white">Shirts</h2>
                    </div>
                    <div className="page-padding mb-14">
                        <ShirtsGrid />
                    </div>
                    <div ref={PantaloonsRef} className={`${styles.sectionTitleContainer} flex bg-white mb-6 pt-6 justify-around laptop:bg-dkblue laptop:justify-start`}>
                        <h2 className="text-dkblue laptop:text-white">Pantaloons</h2>
                    </div>
                    <div className="page-padding mb-14">
                        <PantaloonsGrid />
                    </div>
                    <div ref={HoodiesAndHoodlessRef} className={`${styles.sectionTitleContainer} flex bg-white mb-6 pt-6 justify-around laptop:bg-dkblue laptop:justify-start`}>
                        <h2 className={`${styles.hoodiesTitleShortened} text-dkblue laptop:text-white`}>Hoodies/Hoodless</h2>
                        <h2 className={`${styles.hoodiesTitleFull} text-dkblue laptop:text-white`}>Hoodies & Hoodless</h2>
                    </div>
                    <div className="page-padding mb-14">
                        <HoodiesAndHoodlessGrid />
                    </div>
                    <div ref={KnickKnacksRef} className={`${styles.sectionTitleContainer} flex bg-white mb-6 pt-6 justify-around laptop:bg-dkblue laptop:justify-start`}>
                        <h2 className="text-dkblue laptop:text-white">Knick Knacks</h2>
                    </div>
                    <div className="page-padding mb-14">
                        <KnickKnacksGrid />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductsMenu;